import React, {
  useContext,
  SelectHTMLAttributes,
  ChangeEventHandler,
  Context,
} from 'react';
import { useValidInputCtx } from '../../hooks/useValidInputCtx';
import * as S from './styled';

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  schemaName: string;
}


export function CustomCheckbox<
  K extends Context<{
    updateFormData: React.Dispatch<
      React.SetStateAction<{
        errors: Record<string, unknown>;
        userInputs: Record<string, unknown>;
      }>
    >;
    formData: {
      errors: Record<string, unknown>;
      userInputs: Record<string, unknown>;
    };
  }>,
>({
  name,
  ctx,
  schemaName,
  children,
}: SelectProps & { ctx: K }) {
  const { formData } = useContext(ctx);
  const {
    inputParams: { onChange, onFocus, onBlur },
    error,
  } = useValidInputCtx(ctx, schemaName, name);

  return (
    <S.InputContainer>
      <>
        <S.Label>
          <S.Checkbox
            id={name}
            className={error ? 'input-error' : ''}
            name={name}
            type="checkbox"
            onChange={onChange as unknown as ChangeEventHandler}
            onFocus={onFocus as unknown as ChangeEventHandler}
            onBlur={onBlur as unknown as ChangeEventHandler}
            checked={
              (typeof formData?.userInputs[name] === 'boolean' &&
                (formData?.userInputs[name] as boolean)) ||
              false
            }
          />
          <span className="text-checkbox">
            {children}
          </span>
        </S.Label>
        {error && <span className='error'>{error as string}</span>}
      </>
    </S.InputContainer>
  );
}
