import * as S from "./styled";
import footerImgBar from "../../../../assets/imgs/footer-deco.png"
import Logo from '../../../../assets/imgs/logo.png';
import { ReactComponent as Instagram } from '../../../../assets/svgs/instagram.svg';
import { ReactComponent as Facebook } from '../../../../assets/svgs/facebook.svg';
import { ReactComponent as Linkedin } from '../../../../assets/svgs/linkedin.svg';
import { ReactComponent as ByGt7 } from '../../../../assets/svgs/by-gt7.svg';
import { Button } from "../../../../components/Button";



export function Footer() {
  return (
    <S.FooterContainer>
      <S.FooterDecoContainer>
        <img src={footerImgBar} alt="pattern decoration" />
      </S.FooterDecoContainer>
      <S.FooterContent>
        <S.FooterLogoContainer>
          <img src={Logo} alt="Logo" />
        </S.FooterLogoContainer>
        <S.FooterSocial>
          <p>Acompanhe nossas redes sociais</p>
          <S.FooterSocialContainer>
            <Instagram />
            <Facebook />
            <Linkedin />
          </S.FooterSocialContainer>
        </S.FooterSocial>
        <S.FooterBtnContainer>
          <p>Fale com um de nossos atendentes</p>
          <a href="tel:+553198684000">
            <Button>(31) 9868-4000</Button>
          </a>
        </S.FooterBtnContainer>
        <S.FooterInfoContainer>
          <p>CNPJ 45.810.370/0001-22 - Razão Social AUDIÇÃO DE TODOS BARREIRO LTDA.
            Inscrição Estadual: 004 303 919 0016</p>
          <a href="/documents/Politica-de-Privacidade-AUDICAO-DE-TODOS-07.11.22.pdf" target="_blank" rel="noreferrer">Termos e políticas de privacidade</a>
          <a href="https://gt7.com.br" target="_blank" rel="noreferrer">
            <ByGt7 />
          </a>
        </S.FooterInfoContainer>
      </S.FooterContent>
    </S.FooterContainer>
  )
}
