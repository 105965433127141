import * as S from "./styled";
import findUsMap from '../../../../assets/imgs/findus-img.png'
import findUsDeco from '../../../../assets/svgs/findus-deco.svg'
import findUsPin from '../../../../assets/svgs/findus-pin.svg'
import findUsClock from '../../../../assets/svgs/findus-whour.svg'
import findUsWpp from '../../../../assets/svgs/whatsapp-icon.svg'
import externalLink from '../../../../assets/svgs/external-link.svg'

export function FindUs() {
  return (
    <S.FindUsContainer id="encontre-nos">
      <S.FindUsImgContainer>
        <S.FindUsDecoContainer>
          <img src={findUsDeco} alt="" />
        </S.FindUsDecoContainer>
        <img src={findUsMap} alt="" />
      </S.FindUsImgContainer>
      <S.FindUsContent>
        <S.FindUsTitle>

          Conheça nossa unidade no Barreiro em Belo Horizonte
        </S.FindUsTitle>
        <S.FindUsList>
          <img src={findUsPin} alt="" />
          Rua Honório Hermeto, 150, Barreiro, Belo Horizonte, MG.
        </S.FindUsList>
        <S.FindUsList>
          <img src={findUsClock} alt="" />
          Funcionamos de segunda a sexta de 08:00 às 18:00 horas
        </S.FindUsList>
        <S.FindUsList>
          <img src={findUsWpp} alt="" />
          (31) 99868-4000
        </S.FindUsList>
        <S.FindUsGoRouteContainer>
          <S.FindUsGoRoute target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=R.+Honório+Hermeto,+150+-+Barreiro,+Belo+Horizonte+-+MG,+30640-110/@-19.9749717,-44.0212585,17z&dir_action=navigate&travelmode=driving">Traçar rota agora</S.FindUsGoRoute>
          <img src={externalLink} alt="" />
        </S.FindUsGoRouteContainer>
      </S.FindUsContent>
    </S.FindUsContainer>
  )
}
