import { ReactElement } from 'react'
import whatsapp from '../../assets/imgs/whatsapp.png'
import * as S from './styled'

export interface WhatsAppProps {
  phone: string
  message: string
}

function WhatsApp ({phone, message}: WhatsAppProps): ReactElement {
  return <S.Container>
    <a href={`https://wa.me/${phone}?text=${encodeURI(message)}`}>
      <img src={whatsapp} alt="WhatsApp" />
    </a>
  </S.Container>
}

export default WhatsApp
