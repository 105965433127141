import './assets/css/reset.css';
import './assets/css/fonts.css';
import './App.css';
import { ThemeProvider } from './theme/Provider';
import { Home } from './pages/Home';
import { ContactFormProvider } from './contexts/ContactFormDataContext';
import WhatsApp from './components/WhatsApp';

function App() {
  return (
    <ThemeProvider>
      <ContactFormProvider>
        <Home />
        <WhatsApp phone='5531998684000' message='Olá! Gostaria de saber mais sobre a Audição de Todos' />
      </ContactFormProvider>
    </ThemeProvider>
  );
}

export default App;
