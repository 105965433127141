import { styled } from '../../../../theme/Provider';

export const AcessoriesContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5rem;
  margin-left: 5rem;
  @media (max-width: 768px) {
    margin-top: 2rem;
    margin-left: 0;
  }
`;

export const AcessoriesTitle = styled.h1`
  color: ${({
    theme: {
      main: { heading },
    },
  }) => heading.color};
  font: ${({
    theme: {
      main: { heading },
    },
  }) => heading.font};
  @media (min-width: 1600px) {
    align-self: flex-start;
  }
  @media (max-width: 768px) {
    color: ${({
      theme: {
        main: { heading },
      },
    }) => heading.color};
    font: ${({
      theme: {
        main: { heading },
      },
    }) => heading.fontMobile};
  }
`;

export const AcessoriesSubtitle = styled.p`
  max-width: 35ch;
  margin-top: 1rem;
  color: ${({
    theme: {
      root: { colors },
    },
  }) => colors.txtPrimary};

  font: ${({ theme: { hero } }) => hero.font};
  strong {
    font-weight: 700;
  }
  @media (max-width: 768px) {
    margin-top: 1rem;
    font: ${({ theme: { hero } }) => hero.fontMobile};
  }
`;

export const AcessoriesList = styled.div`
  display: flex;
  margin-top: 6rem;
  font: ${({ theme: { hero } }) => hero.fontMobile};
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 4rem;
    margin-left: 1.8rem;
  }
`;

export const AcessorieCard = styled.div`
  flex: 1;
  border-top: ${({ theme: { root } }) => `1px solid ${root.colors.primary}`};
  &:last-child {
    border-top: none;
  }
  @media (max-width: 768px) {
    padding-top: 7rem;
    border-top: none;
    border-left: ${({ theme: { root } }) => `1px solid ${root.colors.primary}`};
    &:first-child {
      padding-top: 0;
    }
    /* &:last-child {
      border-left: none;
    } */
  }
`;

export const AcessorieCardImg = styled.div`
  position: absolute;
  margin-top: -2rem;
  @media (max-width: 768px) {
    margin-left: -2rem;
  }
`;

export const AcessorieCardTitle = styled.div`
  margin-top: 3rem;
  font-weight: 700;
`;

export const AcessorieCardSubtitle = styled.div`
  max-width: 31ch;
  margin-top: 0.25rem;
  font-weight: 400;
`;

export const AcessorieContent = styled.div`
  @media (max-width: 768px) {
    position: relative;
    margin-top: -4.5rem;
    margin-left: 3rem;
  }
`
