import { ProductPresentation } from '../../../../components/ProductPresentation';
import product5Img from '../../../../assets/imgs/product-5.png';

import * as S from './styled';
import { Button } from '../../../../components/Button';

const products = [{
  title: <p>Aparelho <strong>RIC</strong></p>,
  img: 'assets/hearing-aid-1.gif',
  benefits: [
    "Aparelho pequeno e discreto",
    "Pode ser utilizado para diversos tipos de perda auditiva",
    "Possui recursos que replicam as propriedades da orelha humana",
    "Redução de microfonia",
    "Possui aplicativo no celular"
  ]
},
{
  title: <p>Aparelho <strong>Intra Auricular</strong></p>,
  img: 'assets/hearing-aid-2.gif',
  benefits: [
    "Aparelho auditivo de adaptação instantânea",
    "Discreto e segue o formato do canal auditivo",
    "Redução de microfonia",
    "Indicado para pacientes mais discretos",
    "Possui aplicativo no celular"
  ]
},
{
  title: <p>Aparelho <strong>Recarregável</strong></p>,
  img: 'assets/hearing-aid-3.gif',
  benefits: [
    "Aparelho recarregável",
    "Sensor de movimento integrado",
    "Cancelamento de ruído de vento",
    "Gerenciador de fala e ruído",
    "Conectividade direta com seu celular",
    "Possui aplicativo no celular"
  ]
},
{
  title: <p>Aparelho <strong>Slim</strong></p>,
  img: 'assets/hearing-aid-4.gif',
  benefits: [
    "Aparelho em grande estilo",
    "Design moderno",
    "Prático e fácil de usar",
    "Aparelho recarregável",
    "Acompanha carregador portátil",
    "Utilizado em diversas perdas Auditivas",
    "Gerenciador de fala e ruído",
    "Conectividade direta com Iphone e Android",
    "Possui aplicativo no celular"
  ]
},
{
  title: <p>Aparelho <strong>BTE super power</strong></p>,
  img: product5Img,
  benefits: [
    "Solução ideal para as pessoas que possuem perdas auditivas profundas",
    "Gerenciador de ruído",
    "Redução de microfonia",
    "Baixo consumo de bateria e longa duração"
  ]
}]

export function Products() {
  return (
    <S.ProductsContainer id="produtos">
      <S.ProductsTitle>Produtos</S.ProductsTitle>
      <S.ProductsSubtitle><strong>Encontre o aparelho auditivo</strong> ideal para você.</S.ProductsSubtitle>
      <S.ProductsInfo>Além de te ajudar a ouvir melhor, o aparelho precisa ser ergonômico, confortável e compatível com o seu estilo de vida e grau de perda auditiva. Venha até a nossa unidade e receba o suporte necessário para escolher o seu aparelho.</S.ProductsInfo>
      <S.ProductsDetailsContainer>
        {products.map((product) =>
          <ProductPresentation
            key={`${product.benefits[0].substring(0,5)}`}
            title={product.title}
            img={product.img}
            benefits={product.benefits}
          />
        )}
      </S.ProductsDetailsContainer>
            <a href="https://wa.me/5531998684000?text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20a%20Audi%C3%A7%C3%A3o%20de%20Todos">
              <Button onClick={() => ({})}>Viva a experiência de Ouvir</Button>
            </a>
    </S.ProductsContainer>
  )
}
