import * as S from './styled';
import { ReactComponent as ListIcon } from '../../../../assets/svgs/list-icon.svg';
import heroImg from '../../../../assets/imgs/hero-img.png';
import { Button } from '../../../../components/Button';

export function Hero() {
  return (
    <S.HeroContainer>
      <S.HeroContent>
        <S.HeroTitle>Ouvir é para TODOS</S.HeroTitle>
        <S.HeroSubtitle>Chegou a <strong>Audição de TODOS!</strong> O Centro Auditivo de alta qualidade para você acessar o que sua vida pede.</S.HeroSubtitle>
        <S.BenefitsList>
          <S.ListItem>
            <ListIcon />
            <p>Aparelhos de <strong>alta qualidade</strong> com <strong>preços acessíveis</strong></p>
          </S.ListItem>
          <S.ListItem>
            <ListIcon />
            <p>Atendimento com <strong>equipe de fonoaudiologia especializada</strong></p>
          </S.ListItem>
          <S.ListItem>
            <ListIcon />
            <p><strong>Manutenção</strong> e <strong>garantia</strong></p>
          </S.ListItem>
        </S.BenefitsList>
      </S.HeroContent>
      <S.ButtonContainer>
            <a href="https://wa.me/5531998684000?text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20a%20Audi%C3%A7%C3%A3o%20de%20Todos">
              <Button onClick={() => ({})}>Viva a experiência de Ouvir</Button>
            </a>
      </S.ButtonContainer>
      <S.HeroImgContainer>
        <img src={heroImg} alt="" />
      </S.HeroImgContainer>
    </S.HeroContainer>
  );
}
