import { styled } from "../../theme/Provider";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  grid-column: span 2;
`;

export const Label = styled.label`
  align-self: flex-start;
  margin-bottom: 0.25rem;
  margin-left: 0.75rem;
  color: ${props => props.theme.input.label.color};
  font: ${props => props.theme.input.label.font};
`;

export const TextArea = styled.textarea`
  position: relative;
  overflow: visible;
  width: 100%;
  min-height: 3.25rem;
  box-sizing: border-box;
  padding: 1rem 0.5rem 1rem 1.5rem;
  border: ${props => props.theme.input.border};
  border-radius: ${props => props.theme.input.borderRadius};
  appearance: none;
  background-color: ${props => props.theme.input.background};
  color: ${props => props.theme.input.color};
  font: ${props => props.theme.input.font};
  outline: none;
  resize: none;
  &[data-value='placeholder'] {
    color: ${props => props.theme.input.placeholder.color};
    font: ${props => props.theme.input.placeholder.font};
  }
  &.input-error {
    box-shadow: ${props => `0 0 0 2px ${props.theme.input.error.color}`};
  }
  & + .error-icon {
    position: absolute;
    z-index: 50;
    display: block;
    min-width: 1.375rem;
    min-height: 1.375rem;
    padding-top: 0.125rem;
    border-radius: 50%;
    margin-top: 2.25rem;
    margin-right: 0.2rem;
    background-color: ${props => props.theme.input.error.background};
    color: ${props => props.theme.input.error.color};
    font-weight: 700;
    text-align: center;
    @media (max-width: 48em) {
      margin-left: clamp(13rem, 70.54vw, 22.3rem);
    }
  }
  & ~ span.error {
    position: absolute;
    display: block;
    width: 90%;
    min-height: 1.5rem;
    margin-top: 11rem;
    color: ${({ theme: { root }}) => root.colors.accent };
    font-family: "Ubuntu", sans-serif;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
  }
`;
