import { useState } from 'react';
import * as S from './styled';
import Logo from '../../assets/imgs/logo.png';
import { ReactComponent as MobileIcon } from '../../assets/svgs/mobile-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';

import { Button } from '../Button';
import { TopPageContact } from '../TopPageContact';

type HeaderProps = {
  children: React.ReactNode;
}

export function Header({ children }: HeaderProps) {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  return (<>
    <TopPageContact />
    <S.HeaderContainer>
      <S.BackgroundBlur />
      <S.HeaderContent>
        <S.LogoContainer>
          <img src={Logo} alt="Logo" />
        </S.LogoContainer>
        <S.MenuItemsContainer>
          {children}
          <S.ButtonContainer>
            <a href="https://wa.me/5531998684000?text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20a%20Audi%C3%A7%C3%A3o%20de%20Todos">
              <Button onClick={() => ({})}>Viva a experiência de Ouvir</Button>
            </a>
          </S.ButtonContainer>
        </S.MenuItemsContainer>
        <S.MobileIconContainer>
          {!openMobileMenu ? <MobileIcon onClick={() => setOpenMobileMenu((v) => !v)} />
            : <CloseIcon onClick={() => setOpenMobileMenu((v) => !v)} />}
        </S.MobileIconContainer>
      </S.HeaderContent>
      {openMobileMenu ?
        <S.MobileMenu onClick={() => setOpenMobileMenu((v) => !v)}>
          <S.BackgroundHeaderDeco>
            <div aria-hidden />
            <div aria-hidden />
            <div aria-hidden />
            <div aria-hidden />
            <div aria-hidden />
            <div aria-hidden />
            <div aria-hidden />
          </S.BackgroundHeaderDeco>
          {children}
        </S.MobileMenu>
        : ""}
    </S.HeaderContainer>
    </>
  );
}
