import { styled } from "../../theme/Provider";

export const Form = styled.form`
    position: relative;
    display: grid;
    justify-content: start;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    button {
      max-width: 70%;
    }

    @media (max-width: 1260px) {
      display: block;
    }
`;

export const Success = styled.div`
  background-color: #080;
  box-sizing: border-box;
  color: #fff;
  grid-column: span 2;
  padding: 16px;
  width: 100%;
`

export const Error = styled.div`
  background-color: #800;
  box-sizing: border-box;
  color: #fff;
  grid-column: span 2;
  padding: 16px;
  width: 100%;
`
