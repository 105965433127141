import { useRef } from 'react';
import * as S from './styled';
import { Card } from '../../../../components/Card';
import cardIcon1 from '../../../../assets/svgs/hearing-aid-1.svg';
import cardIcon2 from '../../../../assets/svgs/hearing-aid-2.svg';
import cardIcon3 from '../../../../assets/svgs/hearing-aid-3.svg';
import cardIcon4 from '../../../../assets/svgs/hearing-aid-4.svg';
// import cardIcon5 from '../../../../assets/svgs/hearing-aid-5.svg';
import arrow from '../../../../assets/svgs/picture-arrow.svg';



const cards = [
  {
    title: <p>Viva a experiência de <strong>Ouvir</strong></p>,
    subtitle: "Experimente o seu aparelho auditivo por um tempo antes de comprar.",
    icon: cardIcon1
  },
  {
    title: <p><strong>Preços acessíveis</strong></p>,
    subtitle: "Encontre o aparelho auditivo certo para você com a melhor qualidade e preço justo.",
    icon: cardIcon2
  },
  {
    title: <p>Condições de pagamento facilitadas</p>,
    subtitle: "Oferecemos condições de pagamento facilitadas e personalizadas para que TODOS possam ouvir.",
    icon: cardIcon3
  },
  {
    title: <p><strong>Manutenção e garantia</strong></p>,
    subtitle: "Conte com nossa Garantia de até 2 Anos e manutenção necessária durante a vida útil do seu aparelho auditivo.",
    icon: cardIcon4
  },
  {
    title: <p>Precisa realizar um ajuste? <br /><strong>Fazemos sempre que precisar.</strong></p>,
    subtitle: "",
    icon: cardIcon4
  }
]

export function Services() {
  const nextRef = useRef<HTMLDivElement>(null);
  return (
    <S.ServicesContainer id="servicos">
      <S.ServicesTitle>Serviços e diferenciais</S.ServicesTitle>
      <S.ServicesInfo>Combinamos <strong>tecnologia, atendimento diferenciado, preços acessíveis e condições de pagamento facilitadas</strong> para que TODOS possam ouvir.</S.ServicesInfo>
      <S.ScrollCardsContainer>
        <S.BackArrowContainer onClick={() => { nextRef.current?.scrollBy({ left: -175, behavior: 'smooth' }) }}>
          <img src={arrow} alt="próximo" />
        </S.BackArrowContainer>
        <S.CardsContainer ref={nextRef}>
          {cards.map((card) => <Card
            key={`${Math.floor(Math.random() * 100)}`}
            title={card.title}
            subtitle={card.subtitle}
            icon={card.icon}
          />)}
        </S.CardsContainer>
        <S.NextArrowContainer onClick={() => { nextRef.current?.scrollBy({ left: 175, behavior: 'smooth' }) }}>
          <img src={arrow} alt="próximo" />
        </S.NextArrowContainer>
      </S.ScrollCardsContainer>
    </S.ServicesContainer>
  )
}
