import * as S from './styled';

export function BakgroundDecoration() {
  return (
    <S.BackgroundDecorationContainer aria-hidden>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
        <div aria-hidden/>
    </S.BackgroundDecorationContainer>
  )
}
