import { Header } from "../../components/Header";
import * as S from './styled';
import { ReactComponent as TopDecoration } from "../../assets/svgs/top-left-decoration.svg"
import { Hero } from "./Sections/Hero";
import { AboutUs } from "./Sections/AboutUs";
import { Services } from "./Sections/Services";
import { Products } from "./Sections/Products";
import { Acessories } from "./Sections/Acessories";
import { FindUs } from "./Sections/FindUs";
import { ContactUs } from "./Sections/ContactUs";
import { Footer } from "./Sections/Footer";
import { BakgroundDecoration } from "../../components/BackgroundDecoration";

export function Home() {
  return (
    <S.HomeContainer>
      <BakgroundDecoration />
      <S.TopDecorationContainer aria-hidden>
        <TopDecoration />
      </S.TopDecorationContainer>
      <Header>
        <a href="#sobre-nos">Sobre nós</a>
        <a href="#servicos">Serviços</a>
        <a href="#produtos">Produtos</a>
        <a href="#acessorios">Acessórios</a>
        <a href="#contato">Contato</a>
      </Header>
      <S.ContentContainer>
        <Hero />
        <AboutUs />
        <Services />
        <Products />
        <Acessories />
        <FindUs />
        <ContactUs />
        <Footer />
      </S.ContentContainer>
    </S.HomeContainer>
  );
}
