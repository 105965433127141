import * as S from './styled';
import { ReactComponent as PinLocation } from '../../assets/svgs/pin-location.svg';
import { ReactComponent as WhatsappLogo } from '../../assets/svgs/whatsapp-icon.svg';


export function TopPageContact() {
  return (
    <S.TopPageContainer>
      <S.InfoContainer>
        <PinLocation />
        <span>BARREIRO</span>
      </S.InfoContainer>
      <S.InfoContainer>
        <WhatsappLogo />
        <span>(31) 99868-4000</span>
      </S.InfoContainer>
    </S.TopPageContainer>
  );
}
