import { styled } from '../../theme/Provider';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  grid-column: span 2;
`;

export const Label = styled.label`
  flex-direction: row;
  align-self: flex-start;
  margin-top: 0px;
  margin-bottom: 0.25rem;
  margin-left: 0.75rem;
  color: ${props => props.theme.input.label.color};
  font: ${props => props.theme.input.label.font};

  span {
    margin-left: 7px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  span.modal-link {
    margin-left: 0;
    color: rgba(0, 161, 255, 1);
    cursor: pointer;
    text-decoration: underline;
  }
  & ~ span.error {
    position: absolute;
    display: block;
    width: 90%;
    min-height: 1.5rem;
    margin-top: 2rem;
    margin-right: 8rem;
    color: ${({ theme: { root }}) => root.colors.accent };
    font-family: "Ubuntu", sans-serif;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
  }
  strong {
    font-weight: 700;
  }
`;

export const Checkbox = styled.input`
  width: 1rem;
  height: 1rem;
  box-sizing: border-box;
  border: none;
  background-color: ${({ theme: { input } }) => input.background};
`;
