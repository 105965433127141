import { styled } from '../../theme/Provider';


export const ProductImgContainer = styled.div`
  display: flex;
  flex: 1 50%;
  flex-shrink: 1;
  align-items: center;
  justify-content: flex-start;
  img {
    position: absolute;
    z-index: 10;
    width: 100%;
    max-width: 32.75rem;
    border-radius: 1.875rem;
    margin-left: 0.125rem;
    aspect-ratio: 1.12;
  }
  @media (max-width: 480px) {
    img {
      max-width: 19.753rem;
    }
  }
`;

export const ProductPresentationContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 4rem;
  span {
    display: block;
    margin-top: 2rem;
    font-family: 'Quicksand', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
  }
  /* Workaround for last product without gif */
  &:nth-child(5) > div:nth-child(1) {
    img {
      position: relative;
    }
    & > div:nth-child(1) {
      display: none;
    }
  }
`;

export const ProductContent = styled.div`
  display: flex;
  flex: 1 50%;
  flex-direction: column;
  flex-shrink: 1;
  align-items: flex-start;
  justify-content: center;
`;
export const ProductTitle = styled.p`
  color: ${({ theme: { root } }) => root.colors.txtPrimary};
  font: ${({
    theme: {
      main: { heading },
    },
  }) => heading.fontMobile};
  font-weight: 400;
  strong {
    font-weight: 700;
  }
  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
`;
export const ProductInfoList = styled.ul`
  margin-top: 2rem;
  color: ${({ theme: { root } }) => root.colors.txtPrimary};
  font: ${({
    theme: {
      main: { paragraph },
    },
  }) => paragraph.font};
`;
export const ProductInfoListItem = styled.li`
  margin-left: 2ch;
  list-style: disc;
`;

export const ImgFrame = styled.div`
  position: relative;
  z-index: 20;
  left: -1rem;
  min-width: 31.375rem;
  min-height: 28.125rem;
  border: ${({ theme: { root } }) => `1px solid ${root.colors.secondary}`};
  border-radius: 2.5rem;
  margin-top: 3rem;
  @media (max-width: 480px) {
    left: -0.5rem;
    min-width: 19.519rem;
    min-height: 17.497rem;
    margin-top: 1rem;
  }
`;
