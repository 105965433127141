type IGetThemeFont = {
  fontWeights: { [key: string]: number };
  fontSizes: { [key: string]: string };
  lineHeights: { [key: string]: string };
  fontFamily: string[];
};

export function getThemeFont<T extends IGetThemeFont>(
  fontParams: {
    weight: keyof T['fontWeights'];
    fontSize: keyof T['fontSizes'];
    lineHeight: keyof T['lineHeights'];
  },
  rootTheme: T,
): string {
  return `${rootTheme.fontWeights[fontParams.weight as string]} ${
    rootTheme.fontSizes[fontParams.fontSize as string]
  }/${rootTheme.lineHeights[fontParams.lineHeight as string]} ${
    rootTheme.fontFamily[0]
  }`;
}
