import React, {
  ChangeEventHandler,
  Context,
  FocusEventHandler,
  useContext,
} from 'react';
import { phoneMask } from '../helpers/masks';
import { useAjvValidationWithCtx } from './useAjvValidationWithCtx';

export function useValidInputCtx<
  K extends Context<{
    updateFormData: React.Dispatch<
      React.SetStateAction<{
        errors: Record<string, unknown>;
        userInputs: Record<string, unknown>;
      }>
    >;
    formData: {
      errors: Record<string, unknown>;
      userInputs: Record<string, unknown>;
    };
  }>,
>(ctx: K, schemaName: string, name: string) {
  const { updateFormData, formData } = useContext(ctx);
  const { checkIsValid, isValid, resetOneInputError } = useAjvValidationWithCtx(
    ctx,
    schemaName,
    name,
  );

  const changeInputValue = (newValue: string | boolean) => {
    if (name) {
      updateFormData(
        (oldValues: {
          errors: Record<string, unknown>;
          userInputs: Record<string, unknown>;
        }) => ({
          ...oldValues,
          userInputs: {
            ...oldValues.userInputs,
            [name]: newValue,
          },
        }),
      );
    }
  };
  const onChangeCtx:
    | ChangeEventHandler<HTMLInputElement>
    | ChangeEventHandler<HTMLInputElement> = e => {
    const { name: inputName, value, checked, type } = e.target;

    if (inputName === 'telefone' && value.length > 0)
      return changeInputValue(phoneMask(value));

    if (type === 'checkbox') return changeInputValue(checked);

    return changeInputValue(value);
  };

  const onFocusReset: FocusEventHandler<HTMLInputElement> = () =>
    resetOneInputError();

  const onBlurCheck: React.FocusEventHandler<HTMLInputElement> = () => {
    checkIsValid();
  };

  return {
    isValidInput: isValid,
    error: formData.errors[name],
    inputParams: {
      onChange: onChangeCtx,
      onBlur: onBlurCheck,
      onFocus: onFocusReset,
    } as Partial<React.ParamHTMLAttributes<HTMLInputElement>>,
  };
}
