import { styled } from '../../theme/Provider';

export const BackgroundDecorationContainer = styled.div`
  position: absolute;
  z-index: -10;
  width: 100%;
  min-width: 100vw;
  min-height: 500rem;
  overflow-x: hidden;
  & > div:nth-child(1) {
    /* Ellipse 3 - 2*/

    position: absolute;
    top: 155px;
    left: -188px;
    width: 910px;
    height: 910px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(230, 0, 30, 0.14) 0%,
      rgba(230, 0, 30, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 3 -2*/

      position: absolute;
      top: 3956.42px;
      left: -393.05px;
      width: 857.74px;
      height: 857.74px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(230, 0, 30, 0.14) 0%,
        rgba(230, 0, 30, 0) 100%
      );
    }
  }
  & > div:nth-child(2) {
    /* Ellipse 6 - 2*/

    position: absolute;
    top: 110px;
    left: 594px;
    width: 652px;
    height: 652px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(153, 240, 255, 0.3) 0%,
      rgba(153, 240, 255, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 6 - 2*/

      position: absolute;
      top: 3914px;
      left: 344.04px;
      width: 614.56px;
      height: 614.56px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(153, 240, 255, 0.3) 0%,
        rgba(153, 240, 255, 0) 100%
      );
    }
  }
  & > div:nth-child(3) {
    /* Ellipse 7 - 2*/

    position: absolute;
    top: 666px;
    left: -107px;
    width: 930px;
    height: 930px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(153, 240, 255, 0.3) 0%,
      rgba(153, 240, 255, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 7 -2*/

      position: absolute;
      top: 4438.07px;
      left: -316.7px;
      width: 876.59px;
      height: 876.59px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(153, 240, 255, 0.3) 0%,
        rgba(153, 240, 255, 0) 100%
      );
    }
  }
  & > div:nth-child(4) {
    /* Ellipse 5 -2*/

    position: absolute;
    top: 1353px;
    left: -89px;
    width: 548px;
    height: 548px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(153, 240, 255, 0.3) 0%,
      rgba(153, 240, 255, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 5 -2*/

      position: absolute;
      top: 5085.62px;
      left: -299.73px;
      width: 516.53px;
      height: 516.53px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(153, 240, 255, 0.3) 0%,
        rgba(153, 240, 255, 0) 100%
      );
    }
  }
  & > div:nth-child(5) {
    /* Ellipse 9 -2*/

    position: absolute;
    top: 2007px;
    left: -450px;
    width: 1283px;
    height: 1283px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(230, 0, 30, 0.14) 0%,
      rgba(230, 0, 30, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 9 -2*/

      position: absolute;
      top: 5702.06px;
      left: -640px;
      width: 1209.32px;
      height: 1209.32px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(230, 0, 30, 0.14) 0%,
        rgba(230, 0, 30, 0) 100%
      );
    }
  }
  & > div:nth-child(6) {
    /* Ellipse 4 - 2*/

    position: absolute;
    top: 219px;
    left: 461px;
    width: 1283px;
    height: 1283px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(230, 0, 30, 0.14) 0%,
      rgba(230, 0, 30, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 4 -2*/

      position: absolute;
      top: 4016.74px;
      left: 218.68px;
      width: 1209.32px;
      height: 1209.32px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(230, 0, 30, 0.14) 0%,
        rgba(230, 0, 30, 0) 100%
      );
    }
  }
  & > div:nth-child(7) {
    /* Ellipse 8 -2*/

    position: absolute;
    top: 1288px;
    left: 349px;
    width: 1056px;
    height: 1056px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(230, 0, 30, 0.14) 0%,
      rgba(230, 0, 30, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 8 */

      position: absolute;
      top: 5024.35px;
      left: 113.11px;
      width: 995.35px;
      height: 995.35px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(230, 0, 30, 0.14) 0%,
        rgba(230, 0, 30, 0) 100%
      );
    }
  }
  & > div:nth-child(8) {
    /* Ellipse 9 - 1*/

    position: absolute;
    top: 1948px;
    left: 450px;
    width: 1207px;
    height: 1207px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(153, 240, 255, 0.3) 0%,
      rgba(153, 240, 255, 0) 100%
    );
  }
  & > div:nth-child(9) {
    /* Ellipse 8 - 1*/

    position: absolute;
    top: 4456px;
    left: 349px;
    width: 1056px;
    height: 1056px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(230, 0, 30, 0.14) 0%,
      rgba(230, 0, 30, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 8 - 1 */

      position: absolute;
      top: 878.35px;
      left: -236.89px;
      width: 995.35px;
      height: 995.35px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(230, 0, 30, 0.14) 0%,
        rgba(230, 0, 30, 0) 100%
      );
    }
  }
  & > div:nth-child(10) {
    /* Ellipse 3 - 1*/

    position: absolute;
    top: 3323px;
    left: -188px;
    width: 910px;
    height: 910px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(230, 0, 30, 0.14) 0%,
      rgba(230, 0, 30, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 3 - 1*/

      position: absolute;
      top: -189.58px;
      left: -743.05px;
      width: 857.74px;
      height: 857.74px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(230, 0, 30, 0.14) 0%,
        rgba(230, 0, 30, 0) 100%
      );
    }
  }
  & > div:nth-child(11) {
    /* Ellipse 7 - 1*/

    position: absolute;
    top: 3834px;
    left: -107px;
    width: 930px;
    height: 930px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(153, 240, 255, 0.3) 0%,
      rgba(153, 240, 255, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 7 -1 */

      position: absolute;
      top: 292.07px;
      left: -666.7px;
      width: 876.59px;
      height: 876.59px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(153, 240, 255, 0.3) 0%,
        rgba(153, 240, 255, 0) 100%
      );
    }
  }
  & > div:nth-child(12) {
    /* Ellipse 6 - 1 */

    position: absolute;
    top: 3278px;
    left: 594px;
    width: 652px;
    height: 652px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(153, 240, 255, 0.3) 0%,
      rgba(153, 240, 255, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 6 - 1*/

      position: absolute;
      top: -232px;
      left: -5.96px;
      width: 614.56px;
      height: 614.56px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(153, 240, 255, 0.3) 0%,
        rgba(153, 240, 255, 0) 100%
      );
    }
  }
  & > div:nth-child(13) {
    /* Ellipse 5 - 1*/

    position: absolute;
    top: 4521px;
    left: -89px;
    width: 548px;
    height: 548px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(153, 240, 255, 0.3) 0%,
      rgba(153, 240, 255, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 5 -1 */

      position: absolute;
      top: 939.62px;
      left: -649.73px;
      width: 516.53px;
      height: 516.53px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(153, 240, 255, 0.3) 0%,
        rgba(153, 240, 255, 0) 100%
      );
    }
  }
  & > div:nth-child(14) {
    /* Ellipse 9 - 1*/

    position: absolute;
    top: 5175px;
    left: -450px;
    width: 1283px;
    height: 1283px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(230, 0, 30, 0.14) 0%,
      rgba(230, 0, 30, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 9 -1*/

      position: absolute;
      top: 1556.06px;
      left: -990px;
      width: 1209.32px;
      height: 1209.32px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(230, 0, 30, 0.14) 0%,
        rgba(230, 0, 30, 0) 100%
      );
    }
  }
  & > div:nth-child(15) {
    /* Ellipse 4 - 1*/

    position: absolute;
    top: 3387px;
    left: 461px;
    width: 1283px;
    height: 1283px;
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(230, 0, 30, 0.14) 0%,
      rgba(230, 0, 30, 0) 100%
    );

    @media (max-width: 768px) {
      /* Ellipse 4 -1*/

      position: absolute;
      top: -129.26px;
      left: -131.32px;
      width: 1209.32px;
      height: 1209.32px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(230, 0, 30, 0.14) 0%,
        rgba(230, 0, 30, 0) 100%
      );
    }
  }
  & > div:nth-child(16) {
    /* Ellipse 4 - 3*/
    position: absolute;
    display: none;
    @media (max-width: 768px) {
      /* Ellipse 4 */
      position: absolute;
      top: 2117.74px;
      left: -849.32px;
      display: block;
      width: 1209.32px;
      height: 1209.32px;

      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(230, 0, 30, 0.14) 0%,
        rgba(230, 0, 30, 0) 100%
      );
    }
  }
`;
