import { styled } from '../../theme/Provider';

export const HeaderContainer = styled.header`
  position: fixed;
  margin-top: 2.5rem;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 7rem;
  box-sizing: border-box;
  border-radius: ${({ theme: { header } }) => header.borderRadius};
  border-bottom: ${({ theme: { header } }) =>
  `1px solid ${header.borderBottomColor}`};
  background-color: ${({ theme: { header } }) => header.background};
  & * {
    position: relative;
    z-index: 20;
    filter: blur(0px);
  }
  @media (max-width: 768px) {
    height: 4.5rem;
  }
`;

export const HeaderContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${({ theme: { header } }) => header.padding};
  @media (max-width: 480px) {
    padding: ${({ theme: { header } }) => header.paddingMobile};
  }
`;

export const BackgroundBlur = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: ${({ theme: { header } }) => header.background};
  filter: blur(0.001px);
`;

export const LogoContainer = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
  justify-content: flex-start;
  svg {
    width: 11.875rem;
    height: 4rem;
  }
  @media (max-width: 480px) {
    flex: 1;
    svg {
      width: 7.5rem;
      height: 2.5rem;
    }
  }
`;

export const MenuItemsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 5.5;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  & a {
    flex: 0.8 0 2rem;
    color: ${({ theme: { header } }) => header.color};
    font: ${({ theme: { header } }) => header.font};
    text-decoration: none;
    white-space: nowrap;
  }
  @media (max-width: 1080px) {
    & > * {
      display: none;
    }
    flex: 5;
    gap: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  flex: 1 0;
  padding: 1rem;
  padding-right: 0;
  &&& {
    & > * {
      display: block;
    }
    display: block;
  }
  a {
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
  }
  @media (max-width: 480px) {
    button {
      padding: 0.5rem 0.75rem;
      font-size: 0.625rem;
    }
  }
`;

export const MobileIconContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-left: 0.375rem;
  @media (min-width: 1081px) {
    display: none;
  }
`;

export const Button = styled.button``;

export const MobileMenu = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  flex-direction: column;
  padding-left: 2.375rem;
  margin-top: 7rem;
  background-color: ${({ theme: { root } }) => root.colors.backgroundPrimary};
  font: ${({ theme: { main } }) => main.heading.fontMobile};
  overflow-x: hidden;
  a {
    margin-top: 2rem;
    color: ${({ theme: { root } }) => root.colors.txtPrimary};
    z-index: 200;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    margin-top: 4.475rem;
  }
`;

export const BackgroundHeaderDeco = styled.div`
  position: absolute;
  z-index: 100;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  & > div:nth-child(1) {
    /* Ellipse 3 - 1*/
    display: none;
    @media (max-width: 1080px) {
      /* Ellipse 3 -2*/

      position: absolute;
      top: 3956.42px;
      left: -393.05px;
      display: block;
      width: 857.74px;
      height: 857.74px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(230, 0, 30, 0.14) 0%,
        rgba(230, 0, 30, 0) 100%
      );
    }
  }
  & > div:nth-child(2) {
    /* Ellipse 7 - 1*/
    display: none;
    @media (max-width: 1080px) {
      /* Ellipse 7 - 1*/
      position: absolute;
      top: 292.07px;
      left: -666.7px;
      display: block;
      width: 876.59px;
      height: 876.59px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(153, 240, 255, 0.3) 0%,
        rgba(153, 240, 255, 0) 100%
      );
    }
  }
  & > div:nth-child(3) {
    /* Ellipse 6 - 1*/
    display: none;
    @media (max-width: 1080px) {
      /* Ellipse 6 -1*/

      position: absolute;
      top: -232px;
      left: -5.96px;
      display: block;
      width: 614.56px;
      height: 614.56px;
      border-radius: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(153, 240, 255, 0.3) 0%,
        rgba(153, 240, 255, 0) 100%
      );
    }
  }
  & > div:nth-child(4) {
    /* Ellipse 4 - 1*/
    display: none;
    @media (max-width: 1080px) {
      /* Ellipse 4 -1*/

      position: absolute;
      top: -129.26px;
      left: -131.32px;
      display: block;
      width: 1209.32px;
      height: 1209.32px;

      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(230, 0, 30, 0.14) 0%,
        rgba(230, 0, 30, 0) 100%
      );
    }
  }
`;
