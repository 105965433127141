import * as S from './styled';
import acessorieImg1 from '../../../../assets/svgs/acessorio-1.svg';
import acessorieImg2 from '../../../../assets/svgs/acessorio-2.svg';
import acessorieImg3 from '../../../../assets/svgs/acessorio-3.svg';


const acessories = [
  {
    title: 'Pilhas',
    subtitle: 'Temos todas as pilhas necessárias para o seu aparelho auditivo com o menor preço.',
    img: acessorieImg1,
  },
  {
    title: 'Moldes',
    subtitle: 'Os moldes direcionam melhor o som reproduzido pelo aparelho até o seu canal auditivo. A confecção dos moldes é feita sob medida, personalizada de acordo com o pré-molde.',
    img: acessorieImg2,
  },
  {
    title: 'Desumidificador',
    subtitle: 'O desumidificador tem como função absorver a umidade dos aparelhos auditivos. Caso essa umidade não seja retirada, poderá causar danos ao funcionamento dos aparelhos auditivos.',
    img: acessorieImg3,
  }
]

export function Acessories() {
  return (
    <S.AcessoriesContainer id="acessorios">
      <S.AcessoriesTitle>Acessórios</S.AcessoriesTitle>
      <S.AcessoriesSubtitle>Confira os <strong>acessórios</strong> que são <strong>essenciais para a utilização e manutenção</strong> do seu aparelho auditivo.</S.AcessoriesSubtitle>
      <S.AcessoriesList>
        {acessories.map((acessorie) => (
          <S.AcessorieCard key={acessorie.title.substring(0, 5)}>
            <S.AcessorieCardImg>
              <img src={acessorie.img} alt="" />
            </S.AcessorieCardImg>
            <S.AcessorieContent>
              <S.AcessorieCardTitle>{acessorie.title}</S.AcessorieCardTitle>
              <S.AcessorieCardSubtitle>{acessorie.subtitle}</S.AcessorieCardSubtitle>
            </S.AcessorieContent>
          </S.AcessorieCard>
        ))}
      </S.AcessoriesList>
    </S.AcessoriesContainer>
  )

}
