import { styled } from "../../../../theme/Provider";

export const ServicesContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: -27rem;
  @media (max-width: 1180px) {
    margin-top: -10.875rem;
  }
  @media (max-width: 798px) {
    margin-top: -5rem;
  }
  @media (max-width: 768px) {
    margin-top: -15rem;
  }
  @media (max-width: 690px) {
    margin-top: 4rem;
  }
  @media (max-width: 480px) {
    margin-top: 3.375rem;
  }
`

export const ServicesTitle = styled.h1`
  color: ${({
    theme: {
      main: { heading },
    },
  }) => heading.color};
  font: ${({
    theme: {
      main: { heading },
    },
  }) => heading.font};
  @media (min-width: 1600px) {
    align-self: center;
  }
  @media (max-width: 768px) {
    color: ${({
      theme: {
        main: { heading },
      },
    }) => heading.color};
    font: ${({
      theme: {
        main: { heading },
      },
    }) => heading.fontMobile};
  }
  @media (max-width: 480px) {
    align-self: flex-start;
  }
`;

export const ServicesInfo = styled.p`
  max-width: 60ch;
  margin-top: 0.5rem;
  color: ${({ theme: { hero } }) => hero.color};
  font: ${({
    theme: {
      main: { paragraph },
    },
  }) => paragraph.font};
  strong {
    color: ${({ theme: { hero } }) => hero.emphasis};
    font-weight: 700;
  }
  br {
    margin-bottom: 1.4rem;
  }
  @media (max-width: 768px) {
    margin-top: 1rem;
    font: ${({ theme: { hero } }) => hero.fontMobile};
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  width: 97vw;
  margin-top: 7.5rem;
  gap: 4rem;
  overflow-x: scroll;
  scroll-snap-align: start;
  @media (max-width: 1080px) {
    margin-top: 6.25rem;
  }
  @media (max-width: 768px) {
    gap: 1.5rem;
  }
`
export const NextArrowContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  width: 3.5rem;
  height: 23.75rem;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  @media (max-width: 1080px) {
    margin-top: 6rem;
  }
`

export const BackArrowContainer = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  width: 3.5rem;
  height: 23.75rem;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  img {
    transform: rotate(180deg);
  }
  @media (max-width: 1080px) {
    margin-top: 6rem;
  }
`

export const ScrollCardsContainer = styled.div`
  display: flex;
  width: 100vw;
  padding-left: 3.5rem;
`
