import * as S from './styled';

type IButtonProps = {
  onClick?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  variant?: "primary" | "secondary";
  children: string;
  type?: "button" | "submit";
  disabled?: boolean;
}

export function Button({ disabled = !!false, onClick = () => ({}), variant = "primary", children, type = "button" }: IButtonProps) {
  return (
    <S.Button variant={variant} type={type} onClick={() => onClick()} disabled={disabled}>
      {children}
    </S.Button>
  );
}

Button.defaultProps = {
  onClick: () => ({}),
  variant: "primary",
  type: "button",
  disabled: false,
}
