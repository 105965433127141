import { styled } from "../../../../theme/Provider";

export const FooterContainer = styled.footer`
  margin-top: 6rem;
`
export const FooterDecoContainer = styled.div`
  margin-left: -3.5rem;
  @media (min-width: 1440px) {
    img {
      width: 100%;
      max-height: 6.5rem;
      margin-left: 1rem;
    }
  }
`

export const FooterContent = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 768px) {
    margin-top: 3rem;
    gap: 3rem;
    grid-template-columns: 1fr;
    & div {
      grid-column: span 1;
    }
  }
`;

export const FooterLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const FooterSocialContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`

export const FooterBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font: ${({ theme: { main } }) => main.paragraph.font};
  gap: 0.5rem;
  text-align: center;
  a {
    display: block;
    margin-inline: auto;
    text-align: center;
    text-decoration: none;
  }
  button {
    margin-top: 0.5rem;
  }
`

export const FooterSocial = styled.div`
  font: ${({ theme: { main } }) => main.paragraph.font};
  text-align: center;
  p {
    margin-bottom: 1.5rem;
  }
`

export const FooterInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  font: ${({ theme: { main } }) => main.paragraph.font};
  grid-column: span 3;
  p {
    max-width: 65ch;
    text-align: center;
  }
  a {
    margin-top: 2rem;
  }
  svg:last-child {
    margin-top: 2rem;
  }

`


