import Ajv from 'ajv'
import ajvFormats from 'ajv-formats'
import ajvErrors from 'ajv-errors';
import contactFormSchema from './contactForm.json';

const ajv = new Ajv({ allErrors: true });
ajvFormats(ajv);
ajvErrors(ajv);

ajv.addFormat("more-than-two-words", /(\w+\s\w+)/);
ajv.addFormat("cep-mask", /(\d{5})-(\d{3})/);
ajv.addFormat("phone-mask", /\(\d{2}\) \d{4}-\d{4}/);
ajv.addFormat("cellphone-mask", /\(\d{2}\) \d{5}-\d{4}/);

ajv.addSchema(contactFormSchema, 'contact');

export default ajv;

