import baseStyled, {
  ThemedStyledInterface,
  ThemeProvider as BaseThemeProvider,
} from 'styled-components';
import { exampleTheme } from './root';

export type ITheme = typeof exampleTheme;
export const styled = baseStyled as ThemedStyledInterface<ITheme>;


export function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <BaseThemeProvider theme={exampleTheme}> {children}</BaseThemeProvider>
  );
}
