import * as S from './styled';

type ICardProps = {
  icon: string;
  title: JSX.Element;
  subtitle: string;
}

export function Card({ icon, title, subtitle }: ICardProps) {
  return (
    <S.CardContainer>
      <S.CardIconContainer>
        <img src={icon} alt="hearing aid deco" aria-hidden/>
      </S.CardIconContainer>
      <S.CardTitle>{title}</S.CardTitle>
      <S.CardSubtitle>{subtitle}</S.CardSubtitle>
    </S.CardContainer>
  )
}
