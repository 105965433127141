import { styled } from '../../../../theme/Provider';

export const HeroContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 100vw;
  height: auto;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding-top: 1.219rem;
  overflow-x: hidden;
  button {
    font: ${({ theme: { button } }) => button.primary.fontMobile};
  }
  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
    button {
      position: absolute;
      margin-top: 28rem;
    }
  }
  @media (max-width: 1080px) {
    height: auto;
    flex-wrap: nowrap;
    overflow-x: hidden;
    button {
      position: relative;
      order: 2;
      margin-top: 0.5rem;
    }
  }
`;

export const HeroContent = styled.div`
  max-width: 36rem;
  height: 100%;
  flex: 1 46%;
  order: 0;
  padding-top: 5vw;
  @media (min-width: 1600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 1080px) {
    flex: 1 27%;
  }
  @media (max-width: 768px) {
    padding-top: 1rem;
  }
`;

export const HeroTitle = styled.h1`
  color: ${({
    theme: {
      hero: { heading },
    },
  }) => heading.color};
  font: ${({
    theme: {
      hero: { heading },
    },
  }) => heading.font};
  @media (min-width: 1600px) {
    align-self: flex-start;
  }
  @media (max-width: 768px) {
    color: ${({
      theme: {
        hero: { heading },
      },
    }) => heading.color};
    font: ${({
      theme: {
        hero: { heading },
      },
    }) => heading.fontMobile};
  }
`;

export const HeroSubtitle = styled.p`
  max-width: 50ch;
  margin-top: 1rem;
  color: ${({ theme: { hero } }) => hero.color};
  font: ${({ theme: { hero } }) => hero.font};
  strong {
    color: ${({ theme: { hero } }) => hero.emphasis};
    font-weight: 700;
  }
  @media (max-width: 768px) {
    margin-top: 1rem;
    font: ${({ theme: { hero } }) => hero.fontMobile};
  }
`;

export const HeroImgContainer = styled.div`
  display: flex;
  height: auto;
  flex: 1 50%;
  align-items: center;
  justify-content: center;
  order: 2;
  overflow-x: hidden;
  img {
    width: 100%;
    max-width: 35rem;
  }
  @media (max-width: 1080px) {
    flex: 1 50%;
    img {
      width: 100%;
      max-width: 27.5rem;
      aspect-ratio: 0.975;
    }
  }
  @media (max-width: 1080px) {
    order: 1;
  }
`;

export const BenefitsList = styled.ul`
  margin-top: 1.5rem;
  color: ${({ theme: { hero } }) => hero.color};
  font: ${({ theme: { hero } }) => hero.fontList};
  strong {
    font-weight: 700;
  }
  @media (max-width: 768px) {
    font: ${({ theme: { hero } }) => hero.fontListMobile};
    line-height: 1.375rem;
  }
`;
export const ListItem = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  gap: 1rem;
  @media (max-width: 768px) {
    svg {
      min-width: 1.125rem;
    }
    p {
      max-width: 33ch;
    }
    &:last-child {
      margin-top: 1.188rem;
    }
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  flex: 1;
  flex-basis: 100%;
  flex-shrink: 0;
  order: 3;
  margin-top: -8.5rem;
  overflow-x: hidden;

  a {
    text-decoration: none;
  }

  @media (max-width: 1080px) {
    margin-top: 0.5rem;
    button {
      width: 80%;
      margin: 0 auto;
    }
  }
  @media (max-width: 480px) {
    button {
      width: 100%;
    }
  }
`;
