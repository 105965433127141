import * as S from './styled';

type IProductPresentationProps = {
  img: string;
  title: JSX.Element;
  benefits: string[];
}

export function ProductPresentation({ img, title, benefits }: IProductPresentationProps) {
  return (
    <S.ProductPresentationContainer>
      <S.ProductImgContainer>
        <S.ImgFrame />
        <img src={img} alt="" />
      </S.ProductImgContainer>
      <S.ProductContent>
        <S.ProductTitle>{title}</S.ProductTitle>
        <S.ProductInfoList>
          {benefits.map((benefit) => <S.ProductInfoListItem key={benefit.substring(0, 5)}>{benefit}</S.ProductInfoListItem>)}
        </S.ProductInfoList>
        <span>* Imagens meramente ilustrativas</span>
      </S.ProductContent>
    </S.ProductPresentationContainer>
  )

}
