import { styled } from "../../../../theme/Provider";

export const ContactUsContainer = styled.section`
  display: flex;
  margin-top: 11rem;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 1rem;
    gap: 0;
  }
`
export const ContactUsImgContainer = styled.div`
  flex: 1 50%;
  img {
    width: 120%;
    max-width: 45.5rem;
    margin-left: -5rem;
  }
  @media (max-width: 768px) {
    flex: 1 100%;
    img {
      width: 130%;
    }
  }
`
export const ContactUsFormContainer = styled.div`
  flex: 1 42%;
  @media (max-width: 768px) {
    & div {
      margin-top: 1rem;
      &:nth-last-child(2) {
        margin-top: 0.5rem;
      }
    }
    button {
      width: 100%;
      max-width: 100%;
      margin-top: 2rem;
    }
  }
`

export const ContactUsFormTitle = styled.p`
  margin-top: 3.5rem;
  margin-bottom: 1.5rem;
  font: ${({ theme: { main }}) => main.heading.fontMobile };
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`
