import { getThemeFont } from './helpers';

const colorsPalette = {
  white: '#ffffff',
  paleWhite: '#e2edef',
  ice: '#f8feff',
  blueIce: '#e7f3f5',
  strongRed: '#e6001e',
  red: '#ff0000',
  wine: '#860011',
  lightBlue: '#a3ebf1',
  blueSky: '#3cc5d1',
  bluePool: '#309098',
  greenLime: '#14494e',
  gray: '#636466',
  midGray: '#454648',
  darkGray: '#38393a',
  black: '#000000',
} as const;

const fontSizes = {
  xxxl: '3rem', // 48px
  xxl: '2rem', // 32px
  xl: '1.5rem', // 24px
  l: '1.5rem', // 24px
  m: '1.25rem',
  s: '1rem', // 16px
  ss: '1rem', // 16px
  xs: '0.875rem', // 14px
  xxs: '0.75rem',
} as const;

const lineHeights = {
  xxxl: '3.5rem', // 56px
  xxl: '3.5rem', // 56px
  xl: '2.5rem', // 40px
  l: '2rem', // 32px
  m: '2rem',
  s: '1.5rem', // 24px
  ss: '1.25rem', // 20px
  xs: '1.1rem', // 17.6px
  xxs: '1.25rem',
} as const;

const root = {
  colors: {
    primary: colorsPalette.strongRed,
    secondary: colorsPalette.blueSky,
    accent: colorsPalette.greenLime,
    buttonShadow: `${colorsPalette.strongRed}25`,
    headerBorderColor: `${colorsPalette.paleWhite}`,
    headerBgColor: `${colorsPalette.ice}99`,
    specialHeaderBgColor: `${colorsPalette.blueIce}`,
    error: colorsPalette.red,
    backgroundPrimary: colorsPalette.ice,
    backgroundSecondary: colorsPalette.lightBlue,
    txtPrimary: colorsPalette.darkGray,
    txtSecondary: colorsPalette.midGray,
    txtHeading: colorsPalette.blueSky,
    btnTxt: colorsPalette.white,
    specialBgSectionBlue: colorsPalette.blueSky,
    paragraphSpecialSectionBlue: colorsPalette.greenLime,
    txtSpecialSectionBlue: colorsPalette.bluePool,
    inputTxtPlaceholder: colorsPalette.gray,
    inputBorder: colorsPalette.darkGray,
    boxWithIconBorder: colorsPalette.blueSky,
    black: colorsPalette.black,
  },
  fontFamily: ['Quicksand', 'Ubuntu'],
  fontSizes,
  lineHeights,
  fontWeights: {
    bold: 700,
    normal: 400,
    light: 300,
  },
};

export const exampleTheme = {
  main: {
    paddingDesktop: '7rem 3.75rem 2.5rem 3.75rem',
    paddingMobile: '2.75rem 1.375rem 1.688rem 1.375rem',
    heading: {
      font: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'xxl', lineHeight: 'xxl' },
        root,
      )}`,
      fontMobile: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'l', lineHeight: 'l' },
        root,
      )}`,
      color: root.colors.txtHeading,
    },
    paragraph: {
      font: `normal normal ${getThemeFont(
        { weight: 'normal', fontSize: 's', lineHeight: 's' },
        root,
      )}`,
    }
  },
  topPageInfo: {
    backgroundColor: root.colors.specialHeaderBgColor,
    padding: '0.625rem 3.75rem 0.75rem 3.75rem',
    font: `normal normal ${getThemeFont(
      { weight: 'normal', fontSize: 'xs', lineHeight: 'xs' },
      root,
    )}`,
  },
  header: {
    color: root.colors.txtPrimary,
    font: `normal normal ${getThemeFont(
      { weight: 'bold', fontSize: 's', lineHeight: 's' },
      root,
    )}`,
    background: root.colors.headerBgColor,
    padding: '1.5rem 3.875rem 1.5rem 3.875rem',
    paddingMobile: '1.25rem',
    borderRadius: '0.5rem',
    borderBottomColor: root.colors.headerBorderColor,
  },
  hero: {
    heading: {
      color: root.colors.txtHeading,
      font: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'xxxl', lineHeight: 'xxxl' },
        root,
      )}`,
      fontMobile: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'l', lineHeight: 'l' },
        root,
      )}`,
    },
    emphasis: root.colors.txtSpecialSectionBlue,
    color: root.colors.txtPrimary,
    font: `normal normal ${getThemeFont(
      { weight: 'normal', fontSize: 'xl', lineHeight: 'xl' },
      root,
    )}`,
    fontList: `normal normal ${getThemeFont(
      { weight: 'normal', fontSize: 's', lineHeight: 's' },
      root,
    )}`,
    fontMobile: `normal normal ${getThemeFont(
      { weight: 'normal', fontSize: 's', lineHeight: 's' },
      root,
    )}`,
    fontListMobile: `normal normal ${getThemeFont(
      { weight: 'normal', fontSize: 'xs', lineHeight: 'xs' },
      root,
    )}`,
    background: root.colors.headerBgColor,
    padding: '1.5rem 3.875rem 1.5rem 3.875rem',
    paddingMobile: '1rem',
    borderBottomColor: root.colors.headerBorderColor,
  },
  button: {
    primary: {
      font: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'ss', lineHeight: 'ss' },
        root,
      )}`,
      fontMobile: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'ss', lineHeight: 'ss' },
        root,
      )}`,
      borderRadius: '2.2rem',
      background: {
        default: root.colors.primary,
        hover: root.colors.secondary,
      },
      color: {
        default: root.colors.btnTxt,
        hover: root.colors.primary,
      },
      padding: '1rem 2rem',
      paddingMobile: '0.5rem 1.5rem',
    },
    secondary: {
      font: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'ss', lineHeight: 'ss' },
        root,
      )}`,
      fontMobile: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'xs', lineHeight: 'xxs' },
        root,
      )}`,
      borderRadius: '2.2rem',
      textShadow: `0px 2px 2px ${root.colors.black}40`,
      btnShadow: `3px 2px 30px ${root.colors.primary}30`,
      background: {
        default: root.colors.primary,
        hover: root.colors.secondary,
      },
      color: {
        default: root.colors.btnTxt,
        hover: root.colors.primary,
      },
      padding: '0.75rem 2rem',
      paddingMobile: '0.5rem 1.5rem',
    },
  },
  form: {
    title: {
      color: root.colors.secondary,
      font: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'l', lineHeight: 'm' },
        root,
      )}`,
    },
    padding: '5rem 3.75rem',
    borderRadius: '2.5rem',
    gapRow: '1.5rem',
    gapColumn: '1rem',
    background: root.colors.primary,
  },
  input: {
    label: {
      color: root.colors.txtPrimary,
      font: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'xxs', lineHeight: 'm' },
        root,
      )}`,
    },
    placeholder: {
      color: root.colors.inputTxtPlaceholder,
      font: `italic normal ${getThemeFont(
        { weight: 'light', fontSize: 'xxs', lineHeight: 'xs' },
        root,
      )}`,
    },
    error: {
      background: root.colors.btnTxt,
      color: root.colors.error,
    },
    border: `1px solid ${root.colors.txtSpecialSectionBlue}`,
    borderRadius: '0.625rem',
    background: root.colors.btnTxt,
    color: root.colors.txtHeading,
    font: `normal normal ${getThemeFont(
      {
        weight: 'bold',
        fontSize: 'xxs',
        lineHeight: 'xs',
      },
      root,
    )}`,
  },
  specialBox: {
    background: root.colors.backgroundSecondary,
    padding: "1.5rem 2rem",
    borderRadius: "0.5rem",
    shadow: `0px 0px 3.125rem ${root.colors.secondary}30`,
    heading: {
      color: root.colors.txtSpecialSectionBlue,
      font: `normal normal ${getThemeFont(
        {
          weight: 'normal',
          fontSize: 'l',
          lineHeight: 'l',
        },
        root,
      )}`
    },
    paragraph: {
      color: root.colors.accent,
      font: `normal normal ${getThemeFont(
        {
          weight: 'normal',
          fontSize: 's',
          lineHeight: 's',
        },
        root,
      )}`
    }
  },
  root,
};
