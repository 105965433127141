import { styled } from '../../../../theme/Provider';

export const FindUsContainer = styled.section`
  display: flex;
  gap: 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: -2rem;
  }
`;
export const FindUsDecoContainer = styled.div`
  position: absolute;
  margin-top: 3rem;
  margin-left: -4rem;
  @media (max-width: 480px) {
    display: none;
  }
`;
export const FindUsImgContainer = styled.div`
  flex: 1 55%;
  & > img {
    position: relative;
    z-index: 20;
    width: 100%;
    max-width: 43.5rem;
    margin-top: 7rem;
  }
`;
export const FindUsContent = styled.div`
  display: flex;
  flex: 1 40%;
  flex-direction: column;
  justify-content: center;
  margin-top: 6rem;
  img {
    min-width: 1.5rem;
    margin-right: 1rem;
    aspect-ratio: 1;
  }
  @media (max-width: 768px) {
    margin-top: -2rem;
  }
`;
export const FindUsTitle = styled.h1`
  color: ${({ theme: { main } }) => main.heading.color};
  font: ${({ theme: { main } }) => main.heading.font};
  line-height: 2.5rem;
  @media (max-width: 768px) {
    min-width: 25ch;
    font: ${({ theme: { main } }) => main.heading.fontMobile};
  }
`;
export const FindUsList = styled.div`
  display: flex;
  max-width: 26.25rem;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  font: ${({ theme: { main } }) => main.paragraph.font};
  font-weight: 400;
  &:nth-child(4) {
    img {
      min-width: 1.25rem;
      aspect-ratio: 1;
    }
  }
  &:nth-child(2) {
    margin-top: 2rem;
  }
`;

export const FindUsGoRouteContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8rem;
  color: "#0063bf";
  font: ${({ theme: { main } }) => main.paragraph.font};
  a {
    font-weight: 700;
    text-decoration: none;
  }
  text-decoration: underline;
  img:nth-child(n) {
    max-width: 1rem;
    margin-left: 0.5rem;
    aspect-ratio: 1;
  }
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;
export const FindUsGoRoute = styled.a``;
