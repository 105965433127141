import { ErrorObject } from 'ajv';
import { DataValidationCxt } from 'ajv/dist/types';
import { Context, Dispatch, SetStateAction, useContext, useState } from 'react';
import ajv from '../schemas/validation';

export function useAjvValidationWithCtx<
  T extends {
    errors: Record<string, unknown>;
    userInputs: Record<string, unknown>;
  },
  K extends {
    errors: Record<string, unknown>;
    userInputs: Record<string, unknown>;
  },
>(
  ctx: Context<{ updateFormData: Dispatch<SetStateAction<T>>; formData: K }>,
  schemaName: string,
  field?: keyof K['userInputs'],
  initialData?: { errors: Record<string, unknown> },
) {
  const { updateFormData, formData } = useContext(ctx);
  const [isValid, setIsValid] = useState(false);

  const setAllInputsErrors = (errorsDict: unknown) =>
    updateFormData((oldValues: T) => ({
      ...oldValues,
      errors: errorsDict,
    }));

  const resetAllInputErrors = () => {
    updateFormData((oldValues: T) => ({
      ...oldValues,
      ...(initialData?.errors || {}),
    }));
  };

  const setOneInputError = (newError: string) => {
    if (field) {
      updateFormData((oldValues: T) => ({
        ...oldValues,
        errors: {
          ...oldValues.errors,
          [field]: newError,
        },
      }));
    }
  };

  const resetOneInputError = () => {
    setIsValid(true);
    if (field) {
      updateFormData((oldValues: T) => ({
        ...oldValues,
        errors: {
          ...oldValues.errors,
          [field]: '',
        },
      }));
    }
  };

  const getError = (
    selectedField: keyof K['userInputs'],
    errorObj: ErrorObject,
  ) => {
    const testField = (testingField: string) =>
      errorObj.schemaPath.includes(testingField);
    const hasErrorMessage =
      errorObj.keyword === 'errorMessage' &&
      (testField(`${String(selectedField)}/errorMessage`) ||
        testField(`/properties/${String(selectedField)}/errorMessage`));
    return hasErrorMessage && (errorObj.message || 'Dados inválidos');
  };

  // Get specific user input constraints ajv using #[id].
  //   [id] is equals to the [name] of property at Ctx and
  //   [name] is equals to the [name] of input too
  const checkInput = (selectedField: keyof K['userInputs']) => {
    const inputSchemaName = `${schemaName}#${String(selectedField)}`;
    const isValidInput = ajv.validate(
      { $ref: inputSchemaName },
      formData.userInputs?.[String(selectedField)] as DataValidationCxt<string>,
    );
    if (!isValidInput) {
      // Get custom input error message
      const inputError = ajv.errors?.find(errorObj =>
        getError(selectedField, errorObj),
      );
      if (inputError?.message) {
        setOneInputError(inputError.message);
      }
      return setIsValid(false);
    }
    // }
    // Input válido
    setOneInputError('');
    return setIsValid(true);
  };

  // Check all user inputs and fill errors message
  const checkSchemaAtFormCtx = () => {
    const validate = ajv.getSchema(schemaName);

    if (validate) {
      const isValidSchema = validate(formData?.userInputs);
      if (!isValidSchema) {
        const errorsDict = validate.errors?.reduce((errorObj, errorRaised) => {
          const selectedField = errorRaised.instancePath.substring(1);
          if (
            (errorObj && typeof errorObj?.[selectedField] !== 'string') ||
            (errorObj?.[selectedField] as string).length === 0
          )
            return {
              ...errorObj,
              [selectedField]: getError(selectedField, errorRaised),
            };
          return errorObj;
        }, initialData?.errors || {});
        if (errorsDict) {
          setAllInputsErrors(errorsDict);
        }
        return setIsValid(false);
      }
    }
    // Context em userInputs válido
    resetAllInputErrors();
    return setIsValid(true);
  };

  const checkIsValid = () => {
    if (field) {
      return checkInput(field);
    }
    return checkSchemaAtFormCtx();
  };

  return {
    isValid,
    checkIsValid,
    resetAllInputErrors,
    resetOneInputError,
  };
}

export default useAjvValidationWithCtx;
