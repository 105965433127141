import { styled } from '../../theme/Provider';

export const TopPageContainer = styled.div`
  position: fixed;
  z-index: 100;
  display: flex;
  width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme: { topPageInfo } }) => topPageInfo.padding};
  background-color: ${({ theme: { topPageInfo } }) =>
    topPageInfo.backgroundColor};
  font: ${({ theme: { topPageInfo } }) => topPageInfo.font};
  gap: 6.938rem;
  overflow-x: hidden;
  @media (max-width: 480px) {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.563rem 1.813rem 0.438rem 1rem;
  }
`;
export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.375rem;
`;
