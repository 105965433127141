import { styled } from "../../theme/Provider";

export const CardContainer = styled.div`
  display: flex;
  min-width: 19rem;
  max-width: 19rem;
  height: 22.813rem;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${({ theme: { specialBox }}) => specialBox.padding };
  border-radius: ${({ theme: { specialBox }}) => specialBox.borderRadius };
  background-color: ${({ theme: { specialBox }}) => specialBox.background };
  box-shadow: ${({ theme: { specialBox }}) => specialBox.shadow };
  @media (max-width: 768px) {
    min-width: 17rem;
    max-width: 17rem;
    height: 20.938rem;
  }
`
export const CardTitle = styled.p`
  max-width: 20ch;
  margin-top: 1rem;
  color: ${({ theme: { specialBox }}) => specialBox.heading.color };
  font: ${({ theme: { specialBox }}) => specialBox.heading.font };
  strong {
    font-weight: 700;
  }
  @media (max-width: 768px) {
    max-width: 22ch;
    font: ${({ theme: { specialBox }}) => specialBox.paragraph.font };
  }
`
export const CardSubtitle = styled.p`
  max-width: 25ch;
  margin-top: 1rem;
  color: ${({ theme: { specialBox }}) => specialBox.paragraph.color };
  font: ${({ theme: { specialBox }}) => specialBox.paragraph.font };
`
export const CardIconContainer = styled.div`
  img {
    width: 5rem;
    min-height: 5rem;
  }
  @media (max-width: 768px) {
    img {
      width: 3rem;
      min-height: 3rem;
    }
  }
`
