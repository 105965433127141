import React, {
  useContext,
  SelectHTMLAttributes,
  ChangeEventHandler,
  Context,
} from 'react';
import {useValidInputCtx} from '../../hooks/useValidInputCtx';
import * as S from './styled';

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  schemaName: string;
  label: string;
}


export function CustomTextArea<
  K extends Context<{
    updateFormData: React.Dispatch<
      React.SetStateAction<{
        errors: Record<string, unknown>;
        userInputs: Record<string, unknown>;
      }>
    >;
    formData: {
      errors: Record<string, unknown>;
      userInputs: Record<string, unknown>;
    };
  }>,
>({
  name,
  ctx,
  schemaName,
  children,
  label,
  placeholder
}: SelectProps & { ctx: K }) {
  const { formData } = useContext(ctx);
  const {
    inputParams: { onChange, onFocus, onBlur },
    error,
  } = useValidInputCtx(ctx, schemaName, name);

  return (
    <S.InputContainer>
      <>
        <S.Label htmlFor={name}>{label}</S.Label>
        <S.TextArea
          rows={3}
          id={name}
          placeholder={placeholder}
          className={error ? 'input-error' : ''}
          name={name}
          data-value={
            typeof formData?.userInputs[name] === 'string' &&
            (formData?.userInputs[name] as string)?.length > 0
              ? 'selected'
              : 'placeholder'
          }
          onChange={onChange as unknown as ChangeEventHandler}
          onFocus={onFocus as unknown as ChangeEventHandler}
          onBlur={onBlur as unknown as ChangeEventHandler}
          value={
            (typeof formData?.userInputs[name] === 'string' &&
              (formData?.userInputs[name] as string | number | string[])) ||
            ''
          }
        >
          {children}
        </S.TextArea>
        {error && <span className="error-icon">!</span>}
        {error && <span className='error'>{error as string}</span>}
      </>
    </S.InputContainer>
  );
}
