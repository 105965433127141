import { ITheme, styled } from '../../theme/Provider';

const getStyle = ({
  theme: { button },
  ...props
}: {
  theme: ITheme;
  variant: 'primary' | 'secondary';
}) => {
  if (props.variant === 'secondary') {
    return {
      padding: button.secondary.padding,
      'border-radius': button.secondary.borderRadius,
      'background-color': button.secondary.background.default,
      color: button.secondary.color.default,
      font: button.secondary.font,
      'text-shadow': button.secondary.textShadow,
      'box-shadow': button.secondary.btnShadow,
      '@media(max-width: 481px)': {
        padding: button.secondary.paddingMobile,
        font: button.primary.fontMobile,
      },
      '&:hover': {
        'background-color': button.secondary.background.hover,
        color: button.secondary.color.default,
      },
    };
  }
  return {
    padding: button.primary.padding,
    'border-radius': button.primary.borderRadius,
    'background-color': button.primary.background.default,
    'box-shadow': button.secondary.btnShadow,
    color: button.primary.color.default,
    font: button.primary.font,
    '&:hover': {
      'background-color': button.primary.background.hover,
      color: button.primary.color.default,
    },
    '@media(max-width: 481px)': {
      padding: button.primary.paddingMobile,
      font: button.primary.fontMobile,
    },
  };
};

export const Button = styled.button`
  display: flex;
  max-height: 44px;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  ${getStyle}

  white-space: nowrap;
`;
