const phoneToMaskRegex = /(\d{2})(\d{4})(\d{1,4}).*/;
const cellPhoneToMaskRegex = /(\d{2})(\d{5})(\d{1,4}).*/;
const firtsGroupPhoneToMaskRegex = /(\d{2})(\d{1,4}).*/;

export const phoneMask = (phone: string) => {
  const val = phone.replace(/\D/gi, '')
  if (val.length <= 2) return `(${val}`;
  if (val.length >= 3 && val.length < 6) return `(${val.substring(0,2)}) ${val.substring(2)}`;
  if (val.length <= 6) {
    return val.replace(firtsGroupPhoneToMaskRegex, '($1) $2');
  }
  if (val.length >= 11) {
    return val.replace(cellPhoneToMaskRegex, '($1) $2-$3');
  }
  return val.replace(phoneToMaskRegex, '($1) $2-$3')
}
