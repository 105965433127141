import { Form } from "../../../../components/Form";
import * as S from "./styled";
import contactUsImg from '../../../../assets/imgs/contactus-img.png';

export function ContactUs() {
  return (
    <S.ContactUsContainer id="contato">
      <S.ContactUsImgContainer>
        <img src={contactUsImg} alt="" />
      </S.ContactUsImgContainer>
      <S.ContactUsFormContainer>
        <S.ContactUsFormTitle>Como podemos ajudar?</S.ContactUsFormTitle>
        <Form />
      </S.ContactUsFormContainer>
    </S.ContactUsContainer>
  )

}
