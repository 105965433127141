import { styled } from '../../../../theme/Provider';

export const ProductsContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
  button {
    margin-top: 10rem;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    button {
      margin-top: 2rem;
    }
  }
`;

export const ProductsTitle = styled.h1`
  color: ${({
    theme: {
      main: { heading },
    },
  }) => heading.color};
  font: ${({
    theme: {
      main: { heading },
    },
  }) => heading.font};
  @media (min-width: 1600px) {
    align-self: center;
  }
  @media (max-width: 768px) {
    color: ${({
      theme: {
        main: { heading },
      },
    }) => heading.color};
    font: ${({
      theme: {
        main: { heading },
      },
    }) => heading.fontMobile};
  }
  @media (max-width: 480px) {
    align-self: flex-start;
  }
`;

export const ProductsSubtitle = styled.p`
  margin-top: 1.5rem;
  font: ${({
    theme: {
      main: { heading },
    },
  }) => heading.fontMobile};
  font-weight: 400;
  strong {
    font-weight: 700;
  }
  @media (max-width: 768px) {
    font: ${({
    theme: {
      main: { paragraph },
    },
  }) => paragraph.font};

  }
`;

export const ProductsInfo = styled.p`
  max-width: 64.5ch;
  margin-top: 1.5rem;
  color: ${({ theme: { hero } }) => hero.color};
  font: ${({
    theme: {
      main: { paragraph },
    },
  }) => paragraph.font};
  text-align: center;
  strong {
    color: ${({ theme: { hero } }) => hero.emphasis};
    font-weight: 700;
  }
  br {
    margin-bottom: 1.4rem;
  }
  @media (max-width: 768px) {
    margin-top: 1rem;
    font: ${({ theme: { hero } }) => hero.fontMobile};
    text-align: left;
  }
`;

export const ProductsDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5.5rem;
  gap: 6rem;
  & > div:nth-child(2n) {
    flex-direction: row-reverse;
    & > div:nth-child(2n) {
      flex: 1 40%;
      align-items: flex-start;
      margin-left: 10rem;
    }
  }
  @media (max-width: 1080px) {
    & div {
      flex-direction: column;
      gap: 1rem;
      & > div {
        flex-basis: 100%;
      }
    }
    & > div:nth-child(2n) {
      flex-direction: column;
      & > div:nth-child(2n) {
        margin-left: 0;
      }
    }
  }
  @media (max-width: 768px) {
    gap: 3rem;
  }

`;
