import { styled } from "../../theme/Provider";

export const HomeContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
`
export const TopDecorationContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 6.20rem;
  left: -3.125rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  transform: rotate(10.2deg);
  @media (max-width: 1080px) {
    top: -10%;
    left: -20%;
  }
  @media (max-width: 480px) {
    top: -12%;
    left: -50%;
  }
`

export const ContentContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: ${(props) => props.theme.main.paddingDesktop};
  overflow-x: hidden;
  @media (max-width: 1080px) {
    padding: ${(props) => props.theme.main.paddingMobile};
  }
`
