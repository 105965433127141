import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";

export type IContactUserInputs ={
  nome: string;
  email: string;
  telefone: string;
  assunto: string;
  estado: string;
  cidade: string;
  mensagem: string;
  aceiteEmail: boolean;
  aceiteWhatsApp: boolean;
};


export type IContactFormData = { userInputs: IContactUserInputs } & {
  errors: { [key in keyof IContactUserInputs]: string };
};

export type IContactFormCtx = {
  updateFormData: Dispatch<SetStateAction<IContactFormData>>,
  formData: IContactFormData
};

export const INITIAL_FORM_DATA = {
  userInputs: {
    nome: '',
    email: '',
    telefone: '',
    assunto: '',
    estado: '',
    cidade: '',
    mensagem: '',
    aceiteEmail: false,
    aceiteWhatsApp: false,
  },
  errors: {
    nome: '',
    email: '',
    telefone: '',
    assunto: '',
    estado: '',
    cidade: '',
    mensagem: '',
    aceiteEmail: '',
    aceiteWhatsApp: '',
  },
};

const INITIAL_FORM_CTX: IContactFormCtx = {
  updateFormData: (() =>
    // eslint-disable-next-line no-console
    console.log("Context not found")) as unknown as Dispatch<
    SetStateAction<IContactFormData>
  >,
  formData: INITIAL_FORM_DATA,
};

export const ContactFormContext = createContext(INITIAL_FORM_CTX);

const { Provider: CtxProvider } = ContactFormContext;


export function ContactFormProvider ({ children }: { children: React.ReactNode }) {
  const [formData, updateFormData] = useState<IContactFormData>(INITIAL_FORM_DATA);
  const formContext = {
    formData,
    updateFormData,
  };

  return <CtxProvider value={formContext}>{children}</CtxProvider>;
};
