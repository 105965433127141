import React, {
  BaseSyntheticEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as S from './styled';
import { useAjvValidationWithCtx } from '../../hooks/useAjvValidationWithCtx';
import { ContactFormContext } from '../../contexts/ContactFormDataContext';
import { CustomSelect } from '../CustomSelect';
import { api } from '../../services';
import { CustomInput } from '../CustomInput';
import { CustomTextArea } from '../CustomTextArea';
import { CustomCheckbox } from '../CustomCheckbox';
import { Button } from '../Button';

export type GenericFormCtx = React.Context<{
  updateFormData: React.Dispatch<
    SetStateAction<{
      errors: Record<string, unknown>;
      userInputs: Record<string, unknown>;
    }>
  >;
  formData: {
    errors: Record<string, unknown>;
    userInputs: Record<string, unknown>;
  };
}>;

export function Form() {
  const [assuntos, setAssuntos] = useState(['Geral']);
  const [successMessage, setSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [text, setText] = useState('Enviar')
  const {
    formData: { userInputs },
  } = useContext(ContactFormContext);
  const { checkIsValid } = useAjvValidationWithCtx(ContactFormContext, 'contact');
  const handleSubmit = useCallback(
    async (evt: BaseSyntheticEvent) => {
      evt.preventDefault()
      setSuccessMessage(false)
      setIsDisabled(true)
      setText('Enviando...')
      checkIsValid()
      try {
        await api.post('/api/index.php', userInputs)
        setSuccessMessage(true)
      } catch (err) {
        setErrorMessage(true)
      }
      setIsDisabled(false)
      setText('Enviar')
    },
    [checkIsValid, userInputs],
  );


  useEffect(() => {
    const loadAssuntos = async () => {
      const responseAssuntos = await api.get('/assuntos');
      setAssuntos(responseAssuntos as unknown as string[]);
    };
    if (assuntos?.length <= 0) {
      // eslint-disable-next-line no-console
      loadAssuntos().catch((e) => console.log(e));
    }
  }, [assuntos?.length]);

  return (
    /* eslint-disable-next-line no-console */
    <S.Form action="" onSubmit={(evt) => { handleSubmit(evt).catch((e) => console.log(e)) }}>
      <CustomInput
        label="Nome"
        schemaName="contact"
        ctx={ContactFormContext as unknown as GenericFormCtx}
        name="nome"
        placeholder="ex. Maria Lúcia Almeida"
      />
      <CustomInput
        label="E-mail"
        schemaName="contact"
        ctx={ContactFormContext as unknown as GenericFormCtx}
        name="email"
        placeholder="ex. anacristina@gmail.com"
      />
      <CustomInput
        label="Telefone"
        schemaName="contact"
        ctx={ContactFormContext as unknown as GenericFormCtx}
        name="telefone"
        placeholder="(00) 00000-0000"
      />
      <CustomSelect
        label="Assunto"
        schemaName="contact"
        ctx={ContactFormContext as unknown as GenericFormCtx}
        name="assunto"
      >
        <option value="" disabled hidden>
          Selecione
        </option>
        {assuntos?.map(assunto => (
          <option key={assunto} value={assunto}>
            {assunto}
          </option>
        ))}
      </CustomSelect>
      <CustomInput
        label="Estado"
        schemaName="contact"
        ctx={ContactFormContext as unknown as GenericFormCtx}
        name="estado"
        placeholder="ex. São Paulo"
      />
      <CustomInput
        label="Cidade"
        schemaName="contact"
        ctx={ContactFormContext as unknown as GenericFormCtx}
        name="cidade"
        placeholder="ex. São Paulo"
      />
      <CustomTextArea
        label="Mensagem"
        schemaName="contact"
        ctx={ContactFormContext as unknown as GenericFormCtx}
        name="mensagem"
        placeholder="ex. Gostaria de experimentar o aparelho auditivo"
      />
      <CustomCheckbox
        schemaName="contact"
        ctx={ContactFormContext as unknown as GenericFormCtx}
        name="aceiteEmail"
      >
        Eu aceito notificação e contato via <strong>E-mail</strong>.
      </CustomCheckbox>
      <CustomCheckbox
        schemaName="contact"
        ctx={ContactFormContext as unknown as GenericFormCtx}
        name="aceiteWhatsApp"
      >
        Eu aceito notificação e contato via <strong>WhatsApp</strong>.
      </CustomCheckbox>
      {successMessage && <S.Success>Sua mensagem foi enviada com sucesso</S.Success>}
      {errorMessage && <S.Error>Ocorreu um erro e sua mensagem não pode ser enviada</S.Error>}
      <Button type="submit" disabled={isDisabled}>{text}</Button>
    </S.Form>
  );
}
