import { styled } from '../../../../theme/Provider';

export const AboutUsContainer = styled.section`
  display: flex;
  width: 100%;
  margin-top: 1.5rem;
  gap: 6.25rem;
  @media (max-width: 768px) {
    gap: 0;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
  @media (max-width: 360px) {
    align-items: flex-start;
  }
`;
export const AboutUsImgContainer = styled.div`
  flex: 1 1 40%;
  margin-left: -3.8rem;
  img {
    width: 100%;
    max-width: 40.375rem
  }
  @media (max-width: 480px) {
    width: 100%;
    flex-basis: 20%;
    align-self: center;
    margin-left: -3rem;
    img {
      width: 100%;
      min-width: 21.625rem;
      max-width: 37.375rem;
      aspect-ratio: 0.5786;
    }
  }
  @media (min-width: 1440px) {
    flex: 1 50%;
  }
`;

export const AboutUsContent = styled.div`
  max-width: 36rem;
  height: 100%;
  flex: 1 1 30%;
  order: 0;
  padding-top: 8vw;
  @media (min-width: 1440px) {
    display: flex;
    max-width: unset;
    flex: 1 50%;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 1080px) {
    flex: 1 27%;
  }
  @media (max-width: 768px) {
    width: 45%;
    padding-top: 1.5rem;
  }
  @media (max-width: 480px) {
    width: 100%;
    max-width: 50ch;
    padding-top: 3rem;
    margin-top: -34vmax;
  }
`;

export const AboutUsTitle = styled.h1`
  color: ${({
    theme: {
      main: { heading },
    },
  }) => heading.color};
  font: ${({
    theme: {
      main: { heading },
    },
  }) => heading.font};
  @media (min-width: 1600px) {
    align-self: flex-start;
  }
  @media (max-width: 768px) {
    color: ${({
      theme: {
        main: { heading },
      },
    }) => heading.color};
    font: ${({
      theme: {
        main: { heading },
      },
    }) => heading.fontMobile};
  }
`;

export const AboutUsSubtitle = styled.p`
  max-width: 50ch;
  margin-top: 1rem;
  color: ${({ theme: { hero } }) => hero.color};
  font: ${({ theme: { hero } }) => hero.font};
  strong {
    color: ${({ theme: { hero } }) => hero.emphasis};
    font-weight: 700;
  }
  @media (max-width: 768px) {
    margin-top: 1rem;
    font: ${({ theme: { hero } }) => hero.fontMobile};
  }
`;

export const AboutUsInfo = styled.p`
  max-width: 40ch;
  margin-top: 1rem;
  color: ${({ theme: { hero } }) => hero.color};
  font: ${({
    theme: {
      main: { paragraph },
    },
  }) => paragraph.font};
  strong {
    color: ${({ theme: { hero } }) => hero.emphasis};
    font-weight: 700;
  }
  br {
    margin-bottom: 1.4rem;
  }
  @media (max-width: 768px) {
    margin-top: 1rem;
    font: ${({ theme: { hero } }) => hero.fontMobile};
  }
  @media (min-width: 1440px) {
    max-width: 60ch;
  }
`;
