import * as S from './styled';
import aboutUsImg from '../../../../assets/imgs/aboutus-img.png';
import aboutUsMobileImg from '../../../../assets/imgs/aboutus-mobile-img.png';

export function AboutUs() {
  return (
    <S.AboutUsContainer id="sobre-nos">
      <S.AboutUsImgContainer>
        <picture>
          <source srcSet={aboutUsMobileImg} media="(max-width: 768px)"/>
          <source srcSet={aboutUsImg} />
          <img srcSet={aboutUsImg} alt="" />
        </picture>
      </S.AboutUsImgContainer>
      <S.AboutUsContent>
        <S.AboutUsTitle>Sobre nós</S.AboutUsTitle>
        <S.AboutUsSubtitle>Ajudamos você a recuperar a sua conexão com os sons do mundo.</S.AboutUsSubtitle>
        <S.AboutUsInfo>
          A <strong>Audição de TODOS</strong> nasceu com a missão de que mais pessoas possam ouvir. <strong>Acreditamos que todos devem ter acesso aos sons da vida</strong>, seja uma declaração de amor, uma música ou até mesmo os pequenos barulhos do dia a dia.
          <br />
          Nós mudamos vidas através da audição, proporcionando mais qualidade e bem-estar para que você aproveite os melhores momentos ao lado de quem ama.
        </S.AboutUsInfo>
      </S.AboutUsContent>
    </S.AboutUsContainer>
  )
}
